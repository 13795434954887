import {
    CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny,
    ICreateActionContext, IGeneric
} from '@msdyn365-commerce/core';
import { Address, Customer } from '@msdyn365-commerce/retail-proxy';
import {
    createReadAsync, updateAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import getCustomer, { GetCustomerInput } from './get-customer.action';

export interface IAddressManagementData {
    address: Address;
}
export interface IAddressManagementInput extends IActionInput {
    address: Address;
    userAccountNumber?: string;
}
/**
 * The input class for the addAddress data action
 */
export class AddressManagementInput implements IAddressManagementInput {
    public address: Address;
    public userAccountNumber?: string;

    constructor(address: Address, userAccountNumber?: string) {
        this.userAccountNumber = userAccountNumber;
        this.address = address;
    }

    public getCacheKey = () => 'AddAddress';
    public getCacheObjectType = () => 'AddAddress';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The createInput method for the addAddress data action
 * @param inputData The input data passed to the createInput method
 */
export const createAddressManagementInput = (
    inputData: ICreateActionContext<IGeneric<IAny>, IAddressManagementData>
): IAddressManagementInput => {
        if (inputData.requestContext.user.isAuthenticated) {
        throw new Error('User is not authenticated. Cannot call UpdateAddress API');
        }

        const { address } = inputData.data || { address: undefined };

        if (!address) {
            throw new Error('address is not provided. Cannot call UpdateAddress API');
        }

        return new AddressManagementInput(address);
    };

export async function doAddressManagementOperation(
    input: IAddressManagementInput,
    ctx: IActionContext,
    operationHandler: (customer: Customer, address: Address) => Customer
): Promise<Address[]> {
    const { userAccountNumber, address } = input;
    const customerInput = new GetCustomerInput(ctx.requestContext.apiSettings, userAccountNumber);
    const customer = await getCustomer(customerInput, ctx);

    if (!customer) {
        throw new Error('Not able to create customer');
    }
    // const customerAddressCount = customer.Addresses?.length || 0;
    // if(customerAddressCount > 0) {
        const newCustomer = operationHandler({ ...customer }, address);
        const updatedCustomer = await updateAsync({ callerContext: ctx }, newCustomer);

        if (!updatedCustomer) {
            throw new Error('Not able to update customer');
        }
        ctx.update(createReadAsync(updatedCustomer.AccountNumber), updatedCustomer);
        return updatedCustomer.Addresses || [];
   // }
   // return [];
}

export function addAddressHandler(customer: Customer, address: Address): Customer {
    customer.Addresses = [...(customer.Addresses || [])];
    customer.Addresses.push(address);
    return customer;
}

export async function addAddressAction(input: IAddressManagementInput, ctx: IActionContext): Promise<Address[]> {
    return doAddressManagementOperation(input, ctx, addAddressHandler);
}

/**
 * The addAddress data action
 * Calls the read RetailServer API to get information about a customer
 * Adds the passed address information to the retireved customer, and
 * then updates that customer via the update RetailServer API.
 */
export default createObservableDataAction<Address[]>({
    id: '@msdyn365-commerce-modules/retail-actions/add-address',
    action: <IAction<Address[]>>addAddressAction,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createAddressManagementInput),
    isBatched: false
});