import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action.js';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery.js';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { RegisterContactForBulkEmailsClass } from './types/register-contact-for-bulk-emails';

// API to opt in to receive bulk emails
function customersQuery(recordId?: undefined): any {
    const key = recordId ? { RecordId: recordId } : null;
    return new DataServiceQuery('FBHCustomers', 'Customer', RegisterContactForBulkEmailsClass, key);
}

export function createRegisterContactForBulkEmailsInput(
    queryResultSettings: IQueryResultSettings,
    externalId: string
): any {
    const query = customersQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHRegisterContactForBulkEmails',
        true,
        RegisterContactForBulkEmailsClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHRegisterContactForBulkEmails' },
        { ExternalId: externalId }
    );
}
export function registerContactForBulkEmailsAsync(context: any, externalId: string): any {
    const request = createRegisterContactForBulkEmailsInput(context.queryResultSettings, externalId);
    return executeAction(request, context);
}
