import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action.js';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery.js';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ProductDeliveryOptionsClass } from './types/product-delivery-options';
import { AccountChangeClass } from './types/account-change';

function customersQuery(recordId?: undefined): any {
    const key = recordId ? { RecordId: recordId } : null;
    return new DataServiceQuery('FBHRegistration', 'Customer', AccountChangeClass, key);
}

export function createSetActiveEcomCustomerByExternalIdInput(
    queryResultSettings: IQueryResultSettings,
    customerAccountNumber: string
): any {
    const query = customersQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHSetActiveEcomCustomerByExternalId',
        true,
        ProductDeliveryOptionsClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHSetActiveEcomCustomerByExternalId' },
        { CustomerAccountNumber: customerAccountNumber }
    );
}

export async function setActiveEcomCustomerByExternalIdAsync({
    context,
    customerAccountNumber
}: any = {}): Promise<any> {
    const request = createSetActiveEcomCustomerByExternalIdInput(
        context.queryResultSettings,
        customerAccountNumber
    );
    return executeAction(request, context);
}
