import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action.js';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery.js';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ProductClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import { ProductDeliveryOptionsClass } from './types/product-delivery-options';

function productsQuery({ recordId, param }: any = {}): any {
    const key = recordId ? { RecordId: recordId } : null;
    const entitySet = param ? `FBHCarts('${param}')` : 'FBHCarts';
    return new DataServiceQuery(entitySet, 'Product', ProductClass, key);
}

export enum DeliveryType {
    PICKUP = 'Pickup',
    DELIVERY = 'Delivery'
}

export interface IDeliveryOptions {
    ProductId: string;
    ProductDescription: string;
    DeliveryOptions: IDeliveryOption[];
}

export interface IDeliveryOption {
    DeliveryType: DeliveryType;
    DeliveryModes: IDeliveryMode[];
}
export interface IDeliveryMode {
    DeliveryMode: string;
    ChargeDescription: string;
    ChargeAmount: number;
    ChargeAmountExclGST: number;
    ChargeMethod: string;
}

export function createGetDeliveryOptionsInput(
    queryResultSettings: IQueryResultSettings,
    ProductRecordId: string,
    salesPriceInclGST: number
): any {
    const query = productsQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHGetDeliveryOptions2',
        true,
        ProductDeliveryOptionsClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHGetDeliveryOptions2' },
        { ProductRecordId: ProductRecordId, SalesPriceInclGST: salesPriceInclGST }
    );
}

export function createGetDeliveryOptionsCartInput(queryResultSettings: IQueryResultSettings, cartId: string): any {
    const query = productsQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHGetDeliveryOptionsByCart',
        true,
        ProductDeliveryOptionsClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHGetDeliveryOptionsByCart' },
        { CartId: cartId }
    );
}

export async function getDeliveryOptionsAsync({
    context,
    recordId,
    salesPriceInclGST,
    cartId
}: any = {}): Promise<any> {
    let request;
    if (recordId && salesPriceInclGST) {
        request = createGetDeliveryOptionsInput(context.queryResultSettings, recordId, salesPriceInclGST);
    } else if (cartId) {
        request = createGetDeliveryOptionsCartInput(context.queryResultSettings, cartId);
    }
    return executeAction(request, context);
}
