import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';
import { readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';

/**
 * Input class for get customer data action
 */
export class GetCustomerInput implements IActionInput {
    public customerAccountNumber?: string;
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, accountNumber?: string) {
        this.customerAccountNumber = accountNumber;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`Customer-${this.customerAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'Customer';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetCustomerInput => {
    const { user, apiSettings } = inputData.requestContext;
    if (!user.token || !user.isAuthenticated) {
        throw new Error('[getCustomer][createInput]: User is not Authenticated.');
    }

    return new GetCustomerInput(apiSettings);
};

const getCustomerAction = async (input: GetCustomerInput, ctx: IActionContext): Promise<Customer> => {
    if (input && ctx.requestContext.user.isAuthenticated) {
        // TO-DO- Need to clean up this code once SDK releases new proxy that handles null.
        return readAsync({ callerContext: ctx }, input.customerAccountNumber || '').then(response => {
            return Array.isArray(response) ? response[0] : response;
        });
    }

    ctx.trace('[getCustomer]Invalid input passed to action');
    return <Customer>{};
};

export const buildCacheKey = (base: string, apiSettings: ICommerceApiSettings, locale?: string): string => {
    return `${base}-chanId:${apiSettings.channelId}-catId:${apiSettings.catalogId}${locale ? `-${locale}` : ''}`;
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-customer',
    action: <IAction<Customer>>getCustomerAction,
    input: createInput
});
