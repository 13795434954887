import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action.js';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery.js';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ContactDetailsClass } from './types/contact-details';

// API to check customer status - currently used to check bulk email opt-in status
function customersQuery(recordId?: undefined): any {
    const key = recordId ? { RecordId: recordId } : null;
    return new DataServiceQuery('FBHCustomers', 'Customer', ContactDetailsClass, key);
}

export function createGetContactDetailsInput(queryResultSettings: IQueryResultSettings, externalId: string): any {
    const query = customersQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHGetContactDetails',
        true,
        ContactDetailsClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHGetContactDetails' },
        { ExternalId: externalId }
    );
}
export function getContactDetailsAsync(context: any, externalId: string): any {
    const request = createGetContactDetailsInput(context.queryResultSettings, externalId);
    return executeAction(request, context);
}
