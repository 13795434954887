import { executeAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action.js';
import { DataServiceQuery } from '@msdyn365-commerce/retail-proxy/dist/DataServiceQuery.js';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ProductWarehouseAvailabilityClass } from './types/product-warehouse-availability';

function productAvailabilityQuery(cartId?: undefined): any {
    const key = cartId ? { CartId: cartId } : null;
    return new DataServiceQuery(
        'FBHCarts',
        'FBHGetEstimatedProductWarehouseAvailability',
        ProductWarehouseAvailabilityClass,
        key
    );
}

export function createGetEstimatedProductWarehouseAvailabilityInput(queryResultSettings: IQueryResultSettings,
    cartId: string
): any {
    const query = productAvailabilityQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'FBHGetEstimatedProductWarehouseAvailability',
        true,
        ProductWarehouseAvailabilityClass,
        'false',
        { bypassCache: 'none', returnEntity: 'FBHGetEstimatedProductWarehouseAvailability' },
        { CartId: cartId }
    );
}

export async function getEstimatedProductWarehouseAvailabilityAsync({
    context,
    cartID
}: any = {}): Promise<any> {
    const request = createGetEstimatedProductWarehouseAvailabilityInput(context.queryResultSettings,cartID);
    return executeAction(request, context);
}
