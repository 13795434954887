export interface IESAMAddressSuggestionsResult {
    UniqueId: number;
    FullAddress: string;
    SourceDesc: string;
}

export interface IESAMFullAddressResult {
    UniqueId: number;
    FullAddress: string;
    SourceDesc: string;
    AddressType: string;
    Deliverable: boolean;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    AddressLine4: string;
    AddressLine5: string;
    DPID: string;
    AddressId: number;
    ParcelId: number;
    Meshblock: number;
    StatsCensusYear: number;
    UnitType: string;
    UnitValue: string;
    LevelType: string;
    LevelValue: string;
    StreetNumber: number;
    StreetAlpha: string;
    RoadName: string;
    RoadType: string;
    RoadSuffix: string;
    SuburbLocality: string;
    RuralDelivery: string;
    Lobby: string;
    TownCity: string;
    Postcode: number;
    BoxBagNumber: string;
    BoxBagType: string;
    Position: any; // Using any type as we're not using this data anyway.
}

/**
 * ESAM Address Input Action
 */
export async function getESAMAddressSuggestions(searchString: string, proxyUrl: string, subscriptionKey: string): Promise<IESAMAddressSuggestionsResult[] | null> {
    let data: IESAMAddressSuggestionsResult[] | null = null;
    await fetch(`${proxyUrl}/locationservice/addresses`, {
        method: 'POST',
        headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ searchString: searchString })
    })
        .then((response) => response.json())
        .then((response) => {
            data = response;
        })
        .catch((error) => {
            console.error(error);
        });
    return data;
}

export async function getESAMFullAddress(uniqueId: number, proxyUrl: string, subscriptionKey: string): Promise<IESAMFullAddressResult | null> {
    let data: IESAMFullAddressResult | null = null;
    await fetch(`${proxyUrl}/locationservice/fulladdress`, {
        method: 'POST',
        headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ searchString: uniqueId })
    })
        .then((response) => response.json())
        .then((response) => {
            data = response[0];
        })
        .catch((error) => {
            console.error(error);
        });
    return data;
}
